<template>
  <div>
    <div ref="titleBar" class="title flexB">
      <h1>{{subMenu}}</h1>
<!--      <div class="title siteInfo" style="padding-right: 0;">
        <div class="sitebox">
          <span class="bold">No.{{ event.viewId != null ? event.viewId : event.eventId }}</span>
          <span class="bold">{{ event.title? event.title : "-" }}</span>
          <span>{{ event.address? event.address : "-"}}</span>
          <span>{{ department.name? department.name : "-" }}</span>
          <span>{{ event.managerName? event.managerName : "-" }}</span>
          <span>{{ event.managerPhone? getMask(event.managerPhone) : "-"  }}</span>
          <span>{{ event.managerEmail?  event.managerEmail : "-" }}</span>
        </div>
      </div>-->
    </div>
  </div>
</template>
<style>
  .controlTab li{
    position: relative;
  }
  .controlTab li a{
    padding: 3px 30px;
    height: 26px;
    color: #000;
    border: 1px solid #b9b9b9;
    border-radius: 13px;
    background: white;
    text-align: center;
    margin-right: 16px;
  }
  .controlTab li.active a{
    background: #606060;
    border-radius: 13px;
    border: none;
    color: white;
  }
</style>
<script>
import { fetchEvent } from "@/api/event";
import {mapState} from "vuex";

export default {
  name: "LiveInfoControl",
  computed: {
    ...mapState({
      currentLang: state => state.store.currentLang,
      isAdmin: state => state.store.isAdmin,
      subMenuState: state => state.store.subMenuState,
      options: state => state.store.options,
      navbarState: state => state.store.navbarState,
    }),
  },
  data() {
    return {
      visible: null,
      editMode: false,
      event: {},
      id: null,
      department: {},
      subMenu: "",
      menuName:"",
      option: [],
    };
  },
  created() {
    this.id = this.$route.params.eventId;
  },
  watch : {
    $route (to){
      this.menuName = to.title;
    },
    subMenuState: function () {
      this.handleMenu();
    },
    navbarState: function () {
      this.handleMenu();
    },
  },
  mounted() {
    this.getEventDetail();
    this.handleMenu();

    if(this.subMenu=="통합 모니터"){
      this.$refs.titleBar.remove()
    }
  },
  methods: {
    handleMenu() {
      this.option = this.options[this.navbarState - 1];
      this.subMenu = this.option[this.subMenuState].label;
    },
    handleSubMenu() {
      this.$router.push(this.subMenu);
      
      // var container = document.querySelectorAll(".el-menu-item");
      // var index = this.navbarState - 1;
      // var menu = container.item(index);
      // if (menu.classList.contains("router-link-active") == false) {
      //   menu.classList.add("router-link-active");
      // }
    },
    preset(){
      let id = localStorage.getItem("LiveEventInfoMain");
      if(id != undefined) {
        this.visible = id;
      } else {
        if(this.visible == null){
          this.visible = 1;
        }
        localStorage.setItem("LiveEventInfoMain", this.visible);
      }
    },
    goHome() {
      this.visible = 1;
    },
    handleRefresh() {
      this.$router.go();
    },
    handleTab(id) {
      this.visible = id;
      localStorage.setItem("LiveEventInfoMain", this.visible);
      localStorage.removeItem("LiveEventInfoSub");
    },
    getEventDetail() {
      if(this.id == undefined) {
        return;
      }
      fetchEvent(this.id).then((res) => {
        this.event = res.data.data;
        this.department = res.data.data.managerDepartment;
        if(!this.$store.state.store.isAdmin && this.event.managerDepartment.departmentId != this.$store.state.store.departmentId){
          var filter = this.event.departmentList.filter(item => item.departmentId == this.$store.state.store.departmentId);
          if(this.event.isAuth == true && filter.length == 0) {
            alert(this.$t("user-not-permission"));
            this.$router.push({path:"liveListControl"});
          }
        }
        this.preset();
      });
    },
  },

};
</script>
