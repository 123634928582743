<template>
    <div class="lnb" ref="lnbNav">

        <el-row class="mainMenu">
            <div class="data-content-menu">
                <el-col :span="12">
                    <el-menu
                    class="el-menu-vertical-demo"
                    :router="false"
                    :default-active="selectIndex"
                    :unique-opened="true" >

                        <!-- Ai ChannelList -->
                        <el-submenu index="1">
                            <template slot="title">
                                <img src="@/assets/images/LNB_ai_channel.png" alt="icon" />
                                <span>Ai 채널</span>
                            </template>

                            <el-menu-item route="/" index="AiChannelDetail" v-if="accountId != 'komipo'">
                                <router-link :to="{ name: 'AiChannelDetail' }">
                                    {{ $t("top-select-ai-live-data-ch") }}
                                </router-link>
                            </el-menu-item>
                        </el-submenu>


                      <!-- Ai Streaming -->
                      <el-submenu index="3">
                        <template slot="title">
                          <img src="@/assets/images/LNB_ai_vod.png" alt="icon" />
                          <span>Ai 영상</span>
                        </template>

                        <el-menu-item route="/" index="AiStreaming" v-if="accountId != 'komipo'">

                          <router-link :to="{ name: 'AiStreaming', query: { page: 1 }}">
                            {{ $t("top-select-ai-Streaming-data-list") }}
                          </router-link>
                        </el-menu-item>

                      </el-submenu>

                        <!-- Ai VodList -->
                        <el-submenu index="2">
                            <template slot="title">
                                <img src="@/assets/images/LNB_ai_analyze.png" alt="icon" />
                                <span>Ai 정밀 분석 영상</span>
                            </template>

                            <el-menu-item route="/" index="AiVodList" v-if="accountId != 'komipo'">

                                <router-link :to="{ name: 'AiVodList', query: { page: 1 }}">
                                    {{ $t("top-select-ai-vod-data-list") }}
                                </router-link>
                            </el-menu-item>

                        </el-submenu>


                    </el-menu>
                </el-col>
            </div>
        </el-row>
    </div>
</template>


<script>
import { mapState } from "vuex";
import lang from "@/mixins/lang";


export default {
    mixins: [lang],
    data() {
        return {
            eventId: null,
            selectIndex: null,

            isCollapse: false,
            isUserType: null,
        };
    },
    computed: {
        ...mapState({
            userType: state => state.store.userType,
            accountId: state => state.store.accountId,
        }),

    },
    created() {
        this.eventId = this.$route.params.eventId;
        this.isUserType = localStorage.getItem("userType");
        this.selectIndex = this.$route.name;
    },
    watch:{
        selectIndex(value){
            switch (value) {
                case "AiVodDetail" :
                this.selectIndex = "AiVodList"
                break;
                case "AiChannelDetail" :
                this.selectIndex = "AiChannelList"
                break;
                case "AiStreamingDetail" :
                this.selectIndex = "AiStreaming"
                break;
            }
        },
        $route (to){
        this.selectIndex = to.name;
        }
    },
};
</script>
